body {
  margin: 0;
  padding-top: 50px;
  color: #2f2f2f;
  background-color: #f1f1f1;
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
    serif;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
}

.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1 1;
}

/* text Styling */
p {
  font-family: Arial, Arial, Helvetica, sans-serif;
  font-size: 23px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
}

h2 {
  font-size: 44px;
  font-family: Arial, Arial, Helvetica, sans-serif;
}

h3 {
  font-size: 28px;
  font-family: Arial, Arial, Helvetica, sans-serif;
}

h4 {
  font-size: 23px;
  line-height: 1.25em;
  font-family: Arial, Arial, Helvetica, sans-serif;
}

/* Nav Bar Styling */

/* background colour */
.navbar-inverse {
  background-image: none;
  background-color: #2f2f2f;
  color: #2f2f2f;
}

/* colour of the menu items */
.navbar-inverse .navbar-nav > li > a {
  font-size: 16px;
  color: #f1f1f1;
  font-weight: 500;
  font-family: Arial, Arial, Helvetica, sans-serif;
}

/* colour when hovering over the menu items */
.navbar-inverse .navbar-nav > li > a:hover {
  color: #e7e247;
  text-decoration: underline;
}

/* styling of the menu item After being clicked i.e. active */
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  color: #e7e247;
  text-decoration: none;
  border-radius: 0;
  background-color: #4d5061;
  background-image: none;
}

@media (min-width: 768px) and (max-width: 1220px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
}

/* link Styling */
a {
  color: #f1f1f1;
  text-decoration: none;
}

a:hover {
  color: #e7e247;
  text-decoration: underline;
}

a:focus {
  color: #e7e247;
  text-decoration: none;
}

/* Home Page styling */

.home-title {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
    serif;
}

.homecard {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
}

.homecard div:hover {
  background-color: #e7e247;
}

.homecard-column {
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 991px) {
  .homecard-column {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .homecard-heading {
    min-height: 164px;
  }
}

@media (min-width: 992px) {
  .homecard-logline {
    min-height: 185px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .homecard-set {
    min-height: 86px;
  }
}

.jumbotron p {
  font-size: 4em;
  line-height: 1em;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 420px) {
  .jumbotron p {
    font-size: 3.7em;
    line-height: 1em;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 340px) {
  .jumbotron p {
    font-size: 3.2em;
    line-height: 1em;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* CONTACT Page styling */
.contact-phone1 {
  color: #2f2f2f;
  text-decoration: none;
  font-weight: bold;
}
.contact-phone2 {
  color: #2f2f2f;
  text-decoration: none;
}
.contact-email {
  color: #2f2f2f;
  text-decoration: none;
  font-weight: bold;
}

a.contact-phone1:visited,
a.contact-phone1:hover,
a.contact-phone1:active {
  color: #4d5061;
}
a.contact-phone2:visited,
a.contact-phone2:hover,
a.contact-phone2:active {
  color: #4d5061;
}
a.contact-email:visited,
a.contact-email:hover,
a.contact-email:active {
  color: #4d5061;
}

.ListGroupTitle {
  min-height: 74px;
  font-family: Arial, Arial, Helvetica, sans-serif;
}

@media (max-width: 992px) {
  .ListGroupTitle {
    min-height: 34px;
    font-family: Arial, Arial, Helvetica, sans-serif;
  }
}
@media (max-width: 768px) {
  .ListGroupTitle {
    min-height: 34px;
    font-family: Arial, Arial, Helvetica, sans-serif;
  }
}

.list-custom {
  list-style-position: inside;
}
.list-custom2 {
  list-style: none;
}
.list-custom3 {
  list-style-position: inside;
  text-align: center;
}

.column-border-left {
  border-left: 2px solid #d2d3db;
  padding-bottom: 10px;
}

@media (max-width: 992px) {
  .column-border-left {
    border: none;
  }
}

.column-border-right {
  border-right: 2px solid #d2d3db;
  padding-bottom: 10px;
}

@media (max-width: 992px) {
  .column-border-right {
    border: none;
  }
}

.column-border-left-2 {
  border-left: 2px solid #d2d3db;
  padding-bottom: 10px;
  text-align: center;
}

@media (max-width: 992px) {
  .column-border-left-2 {
    border: none;
    padding-bottom: 10px;
    text-align: center;
  }
}

.table {
  margin: 0 auto;
}

table.table-bordered {
  border: 1px solid #737171;
  margin-top: 20px;
}
table.table-bordered > thead > tr > th {
  border: 1px solid #737171;
}
table.table-bordered > tbody > tr > td {
  border: 1px solid #737171;
}

.custom-button {
  display: block;
  padding-top: 20px;
}

/* Footer */

.footer {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia,
    serif;
}

.footerPosition {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.carousel-indicators {
  position: absolute;
  bottom: 0px;
  left: 35%;
  z-index: 15;
  width: 90%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

@media (max-width: 600px) {
  .carousel-indicators {
    position: absolute;
    bottom: 0px;
    left: 35%;
    z-index: 15;
    width: 90%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
    display: none;
  }
}

.carousel-indicators li {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}

.script-entendre-button {
  background-color: #e7e247;
  border: none;
  color: black;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  line-height: normal;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.script-entendre-button:hover {
  background-color: #d8d242;
  color: black;
}

.no-user-select {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

@media (max-width: 460px) {
  .entendre-table {
    width: 50%;
  }
}

.custom-script-button {
  display: block;
  padding-top: 0px;
}

.script-synopsis-button {
  background-color: #e7e247;
  border: none;
  color: black;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  line-height: normal;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  font-family: Arial, Arial, Helvetica, sans-serif;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.script-synopsis-button:hover {
  background-color: #d8d242;
  color: black;
}

.custom-login-button {
  display: flex;
  padding-top: 0px;
}

.login-button {
  background-color: #36aad1;
  color: #fff;
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-family: "Tahoma, Geneva, sans-serif";
  padding: 10px 40px;
  margin: 0px;
  width: 140px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
}

.login-button:hover {
  background-color: #90caf9;
  color: black;
}

#login,
a:hover {
  text-decoration: none;
}

.custom-contact-button {
  display: flex;
  padding-top: 0px;
  align-items: center;
  justify-content: center;
}

.contact-button {
  background-color: #449b44;
  color: #fff;
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-family: "Tahoma, Geneva, sans-serif";
  padding: 10px 25px;
  margin: 0px;
  width: 140px;
  align-content: center;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
}

.contact-button:hover {
  background-color: #81c784;
  color: black;
}

#contact,
a:hover {
  text-decoration: none;
}

#login {
  margin: 20px;
}

#contact {
  margin: 20px;
}


/* PROSPECTUS  */

@media (max-width: 500px) {
  .market-sales-div {
    flex-wrap: wrap;
  }
}


/* MOVIES */

@media (max-width: 500px) {
  .market-sales-movie {
    width: 250px;
    margin: 0px;
    padding: 20px 0 0 20px;
  }
}



/* ARTIST  */

.market-sales-artist {
  width: 190px;
  margin: 0px 30px 0px 0px;
  text-align: center;
}

@media (max-width: 500px) {
  .market-sales-artist {
    width: 250px;
    margin: 0px;
    padding: 20px 0 0 20px;
    text-align: left;
  }
}



/* QUEEN */

.market-sales-queen {
  width: 250px;
  margin: 0px;
  padding: 0 0 0 0px;
}

@media (max-width: 500px) {
  .market-sales-queen {
    width: 250px;
    margin: 0px;
    padding: 20px 0 0 20px;
  }
}


/* NWA */

.market-sales-nwa {
  width: 250px;
  margin: 0px;
  padding: 0 0 0 10px;
}

@media (max-width: 500px) {
  .market-sales-nwa {
    width: 250px;
    margin: 0px;
    padding: 20px 0 0 20px;
  }
}


/* STONES */

.market-sales-stones {
  width: 250px;
  margin: 0px;
  padding: 0 0 0 0px;
}

@media (max-width: 1200px) {
  .market-sales-stones {
    width: 250px;
    margin: 0px;
    padding: 0 0 0 0px;
  }
}

@media (max-width: 991px) {
  .market-sales-stones {
    width: 250px;
    margin: 0px;
    padding: 0 0 0 0px;
  }
}

@media (max-width: 700px) {
  .market-sales-stones {
    width: 250px;
    margin: 0px;
    padding: 0 0 0 0px;
  }
}

@media (max-width: 560px) {
  .market-sales-stones {
    width: 250px;
    margin: 0px;
    padding: 0 0 0 20px;
  }
}

@media (max-width: 500px) {
  .market-sales-stones {
    width: 250px;
    margin: 0px;
    padding: 20px 0 0 20px;
  }
}
.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}

.Login {
  margin: 0 20px;
  padding: 40px 0px;
}

.Login form {
  margin: 0 auto;

  max-width: 320px;
}

