.Login {
  margin: 0 20px;
  padding: 40px 0px;
}

.Login form {
  margin: 0 auto;

  max-width: 320px;
}
